exports.components = {
  "component---src-pages-2024-celebration-js": () => import("./../../../src/pages/2024/celebration.js" /* webpackChunkName: "component---src-pages-2024-celebration-js" */),
  "component---src-pages-2024-merz-fight-js": () => import("./../../../src/pages/2024/merz-fight.js" /* webpackChunkName: "component---src-pages-2024-merz-fight-js" */),
  "component---src-pages-2024-takeda-js": () => import("./../../../src/pages/2024/takeda.js" /* webpackChunkName: "component---src-pages-2024-takeda-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biomarin-voxogo-js": () => import("./../../../src/pages/biomarin-voxogo.js" /* webpackChunkName: "component---src-pages-biomarin-voxogo-js" */),
  "component---src-pages-dse-js": () => import("./../../../src/pages/dse.js" /* webpackChunkName: "component---src-pages-dse-js" */),
  "component---src-pages-folr-1-js": () => import("./../../../src/pages/folr1.js" /* webpackChunkName: "component---src-pages-folr-1-js" */),
  "component---src-pages-genicos-js": () => import("./../../../src/pages/genicos.js" /* webpackChunkName: "component---src-pages-genicos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kimmtrak-connect-js": () => import("./../../../src/pages/kimmtrak-connect.js" /* webpackChunkName: "component---src-pages-kimmtrak-connect-js" */),
  "component---src-pages-lab-hero-js": () => import("./../../../src/pages/lab-hero.js" /* webpackChunkName: "component---src-pages-lab-hero-js" */),
  "component---src-pages-pluvicto-consumer-digital-js": () => import("./../../../src/pages/pluvicto-consumer-digital.js" /* webpackChunkName: "component---src-pages-pluvicto-consumer-digital-js" */),
  "component---src-pages-pluvicto-consumer-print-js": () => import("./../../../src/pages/pluvicto-consumer-print.js" /* webpackChunkName: "component---src-pages-pluvicto-consumer-print-js" */),
  "component---src-pages-pluvicto-professional-digital-js": () => import("./../../../src/pages/pluvicto-professional-digital.js" /* webpackChunkName: "component---src-pages-pluvicto-professional-digital-js" */)
}

